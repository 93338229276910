.main-app {
  max-width: 1500px;
  margin: auto;
}
/* blog */
p {
  position: relative;
  margin-bottom: 25px;
}

.fa-list-alt {
  position: absolute;
  left: 0;
  top: 13px;
  font-size: 18px;
  color: #e3e3e3;
}

.post {
  margin-left: 26px;
  display: block;
  color: #e3e3e3;
  text-decoration: none;
  font-family: myriad-md;
  font-size: 18px;
  transition: all 0.2s ease;
}

.post:hover {
  color: #ffffff;
  transition: all 0.2s ease;
}

.date {
  color: #e3e3e3;
  font-size: 13px;
  margin: 0 5px;
  margin-left: 26px;
}

.dev-name {
  text-align: right;
  margin: 15px 5%;
}

.blog-wrapper {
  margin: 10px 5% 0 5%;
  background: #ffffff;
  box-shadow: 0px -1px 4px #ccc;
}

header {
  text-align: center;
  font-family: myriad;
  padding: 12px 0;
  font-size: 35px;
  color: #b5b5b5;
}

.blog-wrapper article {
  padding: 15px;
}

.post-date {
  color: #9e9e9e;
  text-align: right;
  margin: 5px;
  position: relative;
  top: 10px;
  padding: 0 12px;
}

.fake-post {
  height: 30px;
  margin: 10px 7% -16px 7%;
  background: #ffffff;
  box-shadow: 0px 0px 4px #ccc;
}

/* body */
.header {
  margin: 0 -15px;
  padding: 5px 10px;
  background: #028dcb;
  color: white;
  font-size: 18px;
  line-height: 30px;
}

.wrapper {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #464646;
  box-shadow: 0px 2px 0px #403c3c;
}

.twitter {
  overflow: auto;
  background: #00a3cd;
  height: 500px;
  position: relative;
}

.blog,
.twitter {
  flex: 1;
}

.blog {
  color: #ffffff;
  padding: 0 15px;
}

.twitter-timeline {
  display: none;
}

@media (max-width: 700px) {
  .wrapper .col-3:first-child {
    padding-right: 15px;
  }
}

.header .fa {
  float: right;
  margin-top: 6px;
  color: #026896;
}

h3 .fa {
  margin-right: 8px;
}

@media screen and (max-width: 600px) {
  .wrapper {
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .me {
    order: -3;
  }

  .blog {
    order: -1;
    width: 100%;
  }
}

/* contact */
.contact-text {
  font-family: myriad-md;
  transform: rotate(-90deg);
  float: left;
  margin: 32px -19px 0 0;
  padding: 10px;
  transition: color 0.5s ease;
}

a {
  text-decoration: none;
  color: #b9b9b9;
}

/* header */
.profile-info {
  flex: 1;
  text-align: left;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  color: #e0e0e0;
}

.profile-info h3 {
  font-size: 50px;
  font-family: myriad;
  font-weight: lighter;
}

.profile-info p {
  font-size: 18px;
}
.profile-image-wrapper {
  flex: 2;
}

.fa {
  color: #7d7d7d;
  padding-right: 4px;
}

h3 {
  font-family: myriad-md;
  margin: 0 0 4px 0;
}

p {
  margin: 4px 0;
  color: #b9b9b9;
}

.wrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .wrapper {
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .profile-image-wrapper {
    order: -2;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .profile-image-wrapper img {
    margin: 0 auto;
    display: block;
  }

  .profile-info {
    text-align: center;
    order: -1;
  }
}

.test {
  pointer-events: none;
  width: 152px;
  height: 152px;
  border-radius: 158px;
  overflow: hidden;
  position: absolute;
  right: calc(50% - 76px);
  z-index: 9;
  top: 3px;
}

.test > div {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: relative;
  left: 100%;

  transition: left 2s;
}

.test > div span {
  font-size: 60px;
  color: white;
  position: relative;
  top: 44px;
  left: 21px;
  opacity: 0;
}

.test > div.enter span {
  opacity: 1;
  -webkit-transition: all 0.1s ease-out 0.1s;
  transition: all 0.1s ease-out 0.1s;
}
.test > div.enter {
  left: 50%;
  -webkit-transition: all 0.1s ease-out 0.1s;
  transition: all 0.1s ease-out 0.1s;
}

/* app */
@font-face {
  font-family: myriad;
  src: url(../public/assets/fonts/myriad-set-pro_thin.ttf);
}

@font-face {
  font-family: myriad-md;
  src: url(../public/assets/fonts/myriad-set-pro_medium.ttf);
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background: #353535;
  font-family: myriad;
}
.wrapper:after {
  display: table;
  content: ' ';
  clear: both;
}
.blog-wrapper table {
  border-collapse: collapse;
}
.blog-wrapper td,
.blog-wrapper th {
  border: 1px solid #c3c3c3;
  padding: 6px 25px;
}
.blog-wrapper th {
  background: #d5d5d5;
}
.blog-wrapper ul {
  margin-top: 0;
}
.blog-wrapper table tr:nth-child(even) {
  background: #f8f8f8;
}

.header-wrapper {
  margin: 0 5%;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d;
}

::-webkit-scrollbar-thumb {
  background: #898989;
}

.privacy-policy ul,
.privacy-policy h2 {
  color: #ffffff;
}
